
const integrationURL = process.env.VUE_APP_URL;
const headers = { 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('access_token'))};

const integrationModule = {
    state: () => ({
        networkElements: [],
        networkElementsByType: [],
        pboAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnMobile: true, inputType: 'text', value: '', disabled: true },
            { name: 'Fat Maximum Capacity', description: 'Maximum Capacity of the FAT', visibleOnMobile: true, inputType: 'number', value: '', disabled: false },
            { name: 'Fat Current Capacity', description: 'Current Capacity of the FAT', visibleOnMobile: true, inputType: 'number', value: '', disabled: false },
            { name: 'Address', description: 'Address of the FAT', visibleOnMobile: true, inputType: 'text', value: '', disabled: false },
            { name: 'Coordinates', description: 'Coordinates of the FAT', visibleOnMobile: true, inputType: 'text', value: '', disabled: false },
            { name: 'Splitter 1', description: 'Existence of Splitter 1', visibleOnMobile: false, inputType: 'dropdown', value: '', disabled: false, splitterExists: false },
            { name: 'Splitter 2', description: 'Existence of Splitter 2', visibleOnMobile: false, inputType: 'dropdown', value: '', disabled: false, splitterExists: false },
            { name: 'Input Cable', description: 'Existence of Input Cable', visibleOnMobile: false, inputType: 'dropdown', value: '', disabled: false, cableExists: false },
            { name: 'Output Cable 1', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: false, inputType: 'dropdown', value: '', disabled: false, cableExists: false },
            { name: 'Output Cable 2', description: 'Existence of Output Cable 2', type: 'Output Cable Selector', visibleOnMobile: false, inputType: 'dropdown', value: '', disabled: false, cableExists: false },
            { name: 'Output Cable 3', description: 'Existence of Output Cable 3', type: 'Output Cable Selector', visibleOnMobile: false, inputType: 'dropdown', value: '', disabled: false, cableExists: false },
            { name: 'Description', description: 'Description of the FAT', visibleOnMobile: true, inputType: 'text', value: '', disabled: false },
        ],
        cableAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Type', description: 'Type of Cable (Aerial/Underground)', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Capacity', description: 'Capacity of the Cable', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Fibers Active', description: 'Active Fibers of the Cable', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Fibers Reserved', description: 'Reserved Fibers of the Cable', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Fibers Dark', description: 'Dark Fibers of the Cable', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Fibers Dedicated', description: 'Dedicated Fibers of the Cable', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Length', description: 'Length of the Cable', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Brand', description: 'Brand of the Cable', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Description', description: 'Description of the Cable', visibleOnMobile: true, value: '', inputType: 'text' },
        ],
        spliceClosureAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnMobile: true, value: '', inputType: 'text' },
            // { id: 'ID', description: 'Identification Number', visibleOnMobile: true},
            { name: 'Max Capacity', description: 'Maximum Capacity of the Splice Closure', visibleOnMobile: true, value: '', inputType: 'number' },
            { name: 'Address', description: 'Address of the Splice Closure', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Coordinates', description: 'Coordinates of the Splice Closure', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Input Cable', description: 'Existence of Input Cable', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 1', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: true},
            { name: 'Output Cable 2', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 3', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 4', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 5', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 6', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 7', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 8', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { id: 'Photo', description: 'Photo of the Splice Closure', visibleOnMobile: true },
            { name: 'Brand', description: 'Brand of the Splice Closure', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Description', description: 'Description of the Splice Closure', visibleOnMobile: true, value: '', inputType: 'text' },
        ],
        zoneAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'HP`s', description: 'Number of homes connected', visibleOnMobile: false, value: '', inputType: 'number'}
        ],
        poleAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Address', description: 'Address of the Pole', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Coordinates', description: 'Coordinates of the pole', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Pole Material', description: 'Material of the pole', visibleOnMobile: true, value: '', inputType: 'text'},
            { name: 'Pole height', description: 'Height of the pole', visibleOnMobile: true, value: '', inputType: 'text'},
            { name: 'Top Diameter', description: 'Top Diameter of the Pole', visibleOnMobile: true, value: '', inputType: 'text'},
            { name: 'Bottom Diameter', description: 'Bottom Diameter of the pole', visibleOnMobile: true, value: '', inputType: 'text'},
            { name: 'Down Guy', description: 'Presence of a Down Guy', visibleOnMobile: false, value: '', inputType: 'dropdown', dropGuyExists: false },
            { name: 'Brand', description: 'Brand of the Material', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Description', description: 'Description of the Material', visibleOnMobile: false, value: '', inputType: 'text'},
        ],
        manholeAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Address', description: 'Address of the Manhole', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Coordinates', description: 'Coordinates of the Manhole', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Manhole Material', description: 'Material of the Manhole', visibleOnMobile: true, value: '', inputType: 'text'},
            { name: 'Manhole Dimensions', description: 'Dimensions of the Manhole', visibleOnMobile: true, value: '', inputType: 'text'},
            { name: 'Manhole Cover', description: 'Material of the Manhole Cover', visibleOnMobile: true, value: '', inputType: 'text'},
            { name: 'Brand', description: 'Brand of the Material', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Description', description: 'Description of the Material', visibleOnMobile: false, value: '', inputType: 'text'},
        ],
        loopAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Loop Length', description: 'Length of the loop', visibleOnMobile: true, value: '', inputType: 'text'},
            { name: 'Brand', description: 'Brand of the Material', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Description', description: 'Description of the Material', visibleOnMobile: false, value: '', inputType: 'text'},
        ],
        fdtSroAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Address', description: 'Address of the FDT-SRO', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Coordinates', description: 'Coordinates of the FDT-SRO', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Maximum Capacity', description: 'Maximum Capacity of the FDT-SRO', visibleOnMobile: true, value: '', inputType: 'number' },
            { name: 'Splitter', description: 'Existence of Splitter', visibleOnMobile: false, inputType: 'dropdown', value: '', disabled: false, splitterExists: false },
            { name: 'Input Cable', description: 'Existence of Input Cable', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 1', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: true},
            { name: 'Output Cable 2', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 3', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 4', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 5', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 6', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 7', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Output Cable 8', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnMobile: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Brand', description: 'Brand of the Material', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Description', description: 'Description of the Material', visibleOnMobile: false, value: '', inputType: 'text'},
        ],
        sfuAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Address', description: 'Address of the SFU', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Coordinates', description: 'Coordinates of the SFU', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'MAC Address', description: 'Mac Address of the Router', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'GPON Address', description: 'GPON Address of the Router', visibleOnMobile: false, value: '', inputType: 'text'}
        ],
        buildingAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnMobile: true, value: '', inputType: 'text' },
            { name: 'Address', description: 'Address of the Building', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Coordinates', description: 'Coordinates of the Building', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Building Name', description: 'Name of the Building', visibleOnMobile: true, value: '',  inputType: 'text'},
            { name: 'Total Flats', description: 'Total Number of apartments/ HP`S', visibleOnMobile: false, value: '', inputType: 'number'},
            { name: 'Floors', description: 'Number of floors', visibleOnMobile: false, value: '', inputType: 'number'},
            { name: 'Flats per floor', description: 'Number of apartments per Floor', visibleOnMobile: false, value: '', inputType: 'number'},
        ],      
        networkElementFeatures: [
            { feature: 'Attributes', description: 'Add Attributes', isConfigurable: true },
            { feature: 'Terminal Access', description: 'Connection to Clients', isConfigurable: false },
            { feature: 'Image', description: 'Capture/Upload Image', isConfigurable: true },
            { feature: 'Zone', description: 'Add Zone', isConfigurable: true },
            { feature: 'Geometry', description: 'Define Type of Geometry', isConfigurable: true },
        ],
        cableCapacities: [
            { label: "6FO Cable", type: "6FO"},
            { label: "12FO Cable", type: "12FO"},
            { label: "24FO Cable", type: "24FO"},
            { label: "48FO Cable", type: "48FO"},
        ],
        cableTypes: [
            { label: "Aerial", type: "Aerial"},
            { label: "Underground", type: "Underground"},
        ],
        lineTypes: [
            { label: "Solid", type: "solid", raw: "1px solid #000000"},
            { label: "Dashed", type: "dashed", raw: "1px dashed #000000"}
        ],
        deleteEndpointMap : {
            'PBO-FAT': '/networkelements/pbofat/template',
            'Cable': '/networkelements/cable/template',
            'Splice Closure': '/networkelements/spliceclosure/template',
            'Zone NRO': '/networkelements/zone/template',
            'Pole': '/networkelements/pole/template',
            'Manhole': '/networkelements/manhole/template',
            'Loop': '/networkelements/loop/template',
            'FDT-SRO': '/networkelements/fdt/template',
            'SFU': '/networkelements/sfu/template',
            'Building': '/networkelements/building/template'
            // Add more types as needed
        },

    }),
    mutations: {
        setNetworkElements(state, networkElements) {
            state.networkElements = networkElements;
        },
        setPboAttributes(state, pboAttributes) {
            state.pboAttributes = pboAttributes;
        },
        setCableAttributes(state, cableAttributes) {
            state.cableAttributes = cableAttributes;
        },
        setSpliceClosureAttributes(state, spliceClosureAttributes) {
            state.spliceClosureAttributes = spliceClosureAttributes;
        },
        setNetworkElementFeatures(state, networkElementFeatures) {
            state.networkElementFeatures = networkElementFeatures;
        },
        setNetworkElementsByType(state, networkElementsByType) {
            state.networkElementsByType = networkElementsByType;
        },
    },
    actions: {
        async fetchNetworkElements(state) {
            const requestOptions = {
                method: 'GET',
                headers
            };
            const res = await fetch(
                integrationURL + '/networkelements/types',
                requestOptions
            );
            const data = await res.json();
            if (data.status == true) {
                state.commit('setNetworkElements', data.data);
            }
        },
        async submitPboFatTemplate(payload) {
            console.log('Payload:', payload);
            const requestOptions = {
                method: 'POST',
                headers,
                body: JSON.stringify(payload),
            };
            const res = await fetch(
                integrationURL + '/pbofat/template',
                requestOptions
            );
            const data = await res.json();
            if (data.status == true) {
                console.log('PBO/FAT added successfully');
            }
        },
        async fetchNetworkElementsByType(state, type) {
            console.log('Type:', type);

            const endpointMap = {
              'PBO-FAT': '/networkelements/pbofat/templates',
              'Cable': '/networkelements/cable/templates',
              'Splice Closure': '/networkelements/spliceclosure/templates',
              'Zone NRO': '/networkelements/zone/templates',
              'Pole': '/networkelements/pole/templates',
              'Manhole': '/networkelements/manhole/templates',
              'Loop': '/networkelements/loop/templates',
              'FDT-SRO': '/networkelements/fdt/templates',
              'SFU': '/networkelements/sfu/templates',
              'Building': '/networkelements/building/templates'
              // Add more types as needed
            };
          
            const endpoint = endpointMap[type];
            
            if (endpoint) {
              const requestOptions = {
                method: 'GET',
                headers,
              };
          
              try {
                const response = await fetch(`${integrationURL}${endpoint}`, requestOptions);
                const data = await response.json();
                state.commit('setNetworkElementsByType', data.data);
              } catch (error) {
                console.error(`Failed to fetch data for type ${type}:`, error);
              }
            } else {
              console.warn('Unknown network element type:', type);
            }
        },
        async deleteNetworkElementsByType(state, payload) {
            console.log('Payload:', payload);
            console.log('State:', state);
            const endpoint = state.state.deleteEndpointMap[payload.type];
            const requestOptions = {
                method: 'DELETE',
                headers,
            };
            const res = await fetch(
                integrationURL + endpoint + '/' + payload.id,
                requestOptions
            );
            const data = await res.json();
            console.log('Data:', data);
            if (data.status === true) {
                console.log('Network Element deleted successfully');
                // this.fetchNetworkElementsByType(payload.type);
                return { status: true, message: 'Network Element deleted successfully' };
            } else {
                console.log('Failed to delete Network Element');
                return { status: false, message: `Failed to delete Network Element: ${data.message}` };
            }
        }
    },
    getters: {
        getNetworkElements: state => state.networkElements,
        getPboAttributes: state => state.pboAttributes,
        getCableAttributes: state => state.cableAttributes,
        getSpliceClosureAttributes: state => state.spliceClosureAttributes,
        getZoneAttributes: state => state.zoneAttributes,
        getPoleAttributes: state => state.poleAttributes,
        getManholeAttributes: state => state.manholeAttributes,
        getLoopAttributes: state => state.loopAttributes,
        getFDTAttributes: state => state.fdtSroAttributes,
        getSFUAttributes: state => state.sfuAttributes,
        getBuildingAttributes: state => state.buildingAttributes,
        getNetworkElementFeatures: state => state.networkElementFeatures,
        getCableCapacities: state => state.cableCapacities,
        getCableTypes: state => state.cableTypes,
        getLineTypes: state => state.lineTypes,
        getNetworkElementsByType: state => state.networkElementsByType,
    }
}

export default integrationModule;